import React, { useState, useContext } from "react";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Navlogo from "../../Assets/Navbar-img/NavLogo.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../Context/AuthContext";
import FreeBanner from "../FreeBanner";
import InfiniteMoving from "../InfiniteMoving";

const Navbar = ({ scrollToKnowledge,home }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };

  // Handle how to use button click
  const handleScrollButton = () => {
    if (location.pathname === "/") {
      // Already on the homepage, scroll to the section
      scrollToKnowledge();
    } else {
      // Navigate to the homepage and then scroll to the section
      navigate("/", { state: { scrollToSection: true } });
    }
  };

  // Define all possible menu items with their paths and authentication requirements
  const menuItems = [
    { title: "HOME", path: "/", requiresAuth: false },
    { title: "MOVEMENT TRACKER", path: "/Data", requiresAuth: true },
    { title: "CALL PUT OI INDICATOR", path: "/CommutativeSum", requiresAuth: true },
    { title: "OI VS PRICE", path: "/StrikeGraph", requiresAuth: true },
    { title: "BUYER VS SELLER", path: "/Buyer_VS_Seller", requiresAuth: true },
    { title: "CUMULATIVE OI", path: "/oi", requiresAuth: true },
    { title: "SCREENER", path: "/screener", requiresAuth: true },
    { title: "ABOUT US", path: "/AboutUs", requiresAuth: false },
    // { title: "REFUND & CANCELLATION", path: "/RefundandCancel", requiresAuth: false },
    { title: "T&C", path: "/termAndCondition", requiresAuth: false },
    { title: "PRIVACY", path: "/Privacypolicy", requiresAuth: false },
  ];

  // Function to handle navigation with authentication check
  const handleNavigation = (path, requiresAuth) => {
    if (requiresAuth && !user) {
      navigate("/login");
    } else {
      navigate(path);
    }
  };

  const menuVariants = {
    closed: {
      x: "100%",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
    open: {
      x: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <div className=" sticky top-0 z-50 w-[100%] overflow-hidden">
      <div className="flex flex-col w-[100vw ]">
        <nav className="bg-[#E6E6FF] w-[100%] h-fit py-[0.5vw] flex flex-row items-center justify-between px-5 sm:px-20">
          <NavLink to="/">
            <div className="left w-auto h-[100%] flex items-center">
              <img className="h-16 cursor-pointer" src={Navlogo} alt="" />
            </div>
          </NavLink>

          <div className="flex items-center gap-2 md:gap-5">
            {!user ? (
              <Link
                to="/login"
                className="button z-20 py-[1vw] md:py-[0.3vw] flex items-center text-[2vw] md:text-[0.8vw] rounded-lg px-[2vw] text-white"
              >
                LOG IN
              </Link>
            ) : (
              <Link
                onClick={handleLogout}
                to="/"
                className="button z-20 py-[1vw] md:py-[0.3vw] flex items-center text-[2.25vw] md:text-[0.8vw] rounded-lg px-[2vw] text-white"
              >
                LOG OUT
              </Link>
            )}

            <button
              onClick={handleScrollButton}
              className="button z-20 py-[1vw] md:py-[0.3vw] flex items-center text-[2.25vw] md:text-[0.8vw] rounded-lg px-[2vw] text-white"
            >
              HOW TO USE
            </button>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-xl md:text-3xl font-bold"
            >
              {isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
            </button>
          </div>
        </nav>

        <div>
         <FreeBanner flag={true} />
        </div>
        <div className="overflow-hidden h-[14vw] md:h-[5vw] ">
          <InfiniteMoving home={home}/>
        </div>
       
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="fixed top-0 right-0 bottom-0 w-full sm:w-96 bg-[#E6E6FF] bg-zinc-100 z-50 shadow-lg overflow-y-auto"
          >
            <div className="p-5 flex flex-col items-center mt-[5vw] h-full">
              <button
                onClick={() => setIsOpen(false)}
                className="absolute top-6 right-6 text-xl md:text-3xl"
              >
                <AiOutlineClose />
              </button>
              <ul className="space-y-5 px-4 sm:w-full">
                {menuItems.map((item, index) => (
                  <li key={index} className="mb-2">
                    <div
                      onClick={() =>
                        handleNavigation(item.path, item.requiresAuth)
                      }
                      className="cursor-pointer flex border-b-[1px] border-black/40 pb-[0.8vw] text-lg font-semibold text-black/90 hover:text-orange-500 transition-colors duration-400"
                    >
                      {item.title}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      
    </div>
  );
};

export default Navbar;
