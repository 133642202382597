import pcr from '../Assets/platform-img/PCR.png'
import price from '../Assets/platform-img/Price.png'
import screener from '../Assets/platform-img/SCREENER.png'
import buyervsseller from '../Assets/platform-img/BUYERvsSELLER.png'
import callvsput from '../Assets/platform-img/CALLvsPUT.png'
import OI from "../Assets/platform-img/OI.png"

export const PagesDetail=[
    {
        id:1,
        heading:"MOVEMENT TRACKER",
        detail:"Decode the market participants by Option chain in real-time to see where (Institutional investors, Foreign investors, Big players, Big fish) were they putting their money, If you know their move, if you know where their money is going, you can easily drive with them. See it in every 2,4,6,8,10 minutes what they are doing.Also see at same time Short Covering, Short Buildup, Long Buildup, Long Unwinding and also see support and residence",
        img:pcr

    },
    {
        id:2,
        heading:"CALL PUT OI INDICATOR",
        detail:"By CALL v/s PUT you can see In 2,4,6,8,10 mint interval increasing/decreasing  Commulative OI ,Change In Oi, percentage change OI with interval.",
        img:callvsput
    },
    {
        id:3,
        heading:"OI VS PRICE",
        detail:"By Call vs put Oi you can see both graf with inreval 2,4,6,8,10 so that you can easly see the movement of call and put OI.",
        img:price
    },
    {
        id:4,
        heading:"CUMULATIVE OI",
        detail:"On this page you can see Cumulative OI data on daily, weekly and monthly basis of Stocks and Indices",
        img:OI
    },
    {
        id:5,
        heading:"SCREENER",
        detail:"Get the best and the simplest real-time trend analysis of all the stocks and indexes",
        img:screener
    },
]