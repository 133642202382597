import axios from 'axios'
import React, { useState,useEffect } from 'react'
import { localapi } from '../Assets/config'
import Card from './Card'
import useMeasure from "react-use-measure"
import {animate, useMotionValue,motion} from "framer-motion"
import { DNA } from 'react-loader-spinner';



const InfiniteMoving = ({home}) => {
    let [ref,{width}]=useMeasure()

    const [data, setdata] = useState([])
    const [twoMin,setTwoMin]=useState()

    const updateTwoMin=()=>{
        const currentTime=new Date()
        setTwoMin(currentTime)
      }
   
      useEffect(()=>{
        const intervalid=setInterval(() => {
          updateTwoMin()
        }, 12000);
        return () => clearInterval(intervalid);
      })

    useEffect(()=>{
        fetchdata();
    },[twoMin])

    useEffect(()=>{
      fetchdata();
    },[])

    const fetchdata=async()=>{
        try {
            const res=await axios.get(`${localapi}/banner`)
            console.log(res.data.data)
            setdata(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    

    const xTranslation=useMotionValue(0)

    useEffect(()=>{
        let controls
        let finalPosition=-width/2-4
        controls=animate(xTranslation,[0,finalPosition],{
            ease:"linear",
            duration:60,
            repeat:Infinity,
            repeatType:"loop",
            repeatDelay:0,
        })
        return controls.stop
    },[xTranslation,width])

    console.log(home)

    return (

    <div className={` ${home ? "flex" : " hidden"} overflow-hidden w-[100vw]`}>

      {data.length>0 ? (<motion.div 
 ref={ref} 
 className='absolute bg-purple-950 left-0 flex flex-row gap-2 scrollbar-hide mt-[-0.8vw] md:mt-[-1.38vw]' style={{x:xTranslation}}
 >
        {[...data,...data].map((item,key)=>(
            <div key={key} className=''>
                    <Card item={item}/>
            </div>
        ))}
    </motion.div>) :
     (<div className='absolute text-[4vw] flex justify-center  md:text-[1vw] w-[100%] text-center items-center text-white bg-purple-950 left-0 flex flex-row gap-2 scrollbar-hide mt-[-0.8vw] md:mt-[-1.38vw]'>
          <DNA
  visible={true}
  height="60"
  width="60"
  ariaLabel="dna-loading"
  wrapperStyle={{}}
  wrapperClass="dna-wrapper"
  />   
      </div>)
      }
      
 
    </div>
   
  )
}

export default InfiniteMoving