import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { localapi } from "../../Assets/config";
import companylogo from "../../Assets/Navbar-img/NavLogo.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; // Import eye icons

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true); // State for password visibility
  const [loginError, setLoginError] = useState(false);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${localapi}/login`, { email, password })
      .then((result) => {
        if (result) {
          if (typeof result.data.data !== 'string') {
            localStorage.setItem("user", JSON.stringify(result.data.data));
          } else {
            localStorage.setItem("user", result.data.data);
          }
          setUser(result.data.data);
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoginError(err.response.data.message);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="w-full bg-[#9698ED] h-[100vh] flex items-center justify-center">
        <div className="container shadow-lg backdrop-blur-md bg-[#ffffffc5] rounded-lg overflow-hidden w-[90%] h-[80%] sm:w-[70%] sm:h-[90%] flex p-[20px]">

          <div className="left group hidden sm:flex items-center justify-center bg-[#9698ED] w-[40%] h-[100%] duration-300 ease-in">
            <div className="image w-[80%] h-[40%]">
              <img
                className="w-[100%] h-[100%] object-cover object-center group-hover:scale-105 duration-300 ease-in"
                src={companylogo}
                alt=""
              />
            </div>
          </div>

          <div className="right w-[100%] h-[100%] sm:w-[60%] flex flex-col items-center sm:items-start  justify-center  px-[7%]">
            <h2 className="text-4xl font-bold mb-8 text-center w-[100%]">Find Your Trade</h2>
            <h2 className="text-2xl font-bold mb-4 text-center w-[100%]">Login</h2>
            <form
              className="w-[100%]  flex flex-col items-center justify-center mb-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-4 w-[100%]">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border border-[#302f2f84] focus:border-black duration-100 ease-in focus:outline-none rounded-md"
                />
              </div>

              <div className="mb-4 w-[100%] relative">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Password
                </label>
                <div className="relative">

                <input
                  type={!showPassword ? "text" : "password"} // Toggle between text and password
                  id="password"
                  name="password"
                  autoComplete="off"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2 border border-[#302f2f84] focus:border-black duration-100 ease-in focus:outline-none rounded-md"
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                >
                  {showPassword ? (
                    <AiFillEyeInvisible size={24} />
                  ) : (
                    <AiFillEye size={24} />
                  )}
                </div>
                  </div>
              </div>

              <button
                type="submit"
                className="button bg-[#9698ED] text-white py-2 px-4 rounded-md w-[100%] duration-300 ease-in"
              >
                Login
              </button>

              <Link
                to="/"
                className="button bg-[#3b3d98] text-white py-2 px-4 rounded-md w-[100%] duration-300 ease-in text-center mt-[15px]"
              >
                Home
              </Link>
            </form>

            <div className="text-[20px] text-red-500 w-full mb-[15px] flex justify-center">
              {loginError && <div>{loginError}</div>}
            </div>

            <Link
              to="/forgot-password"
              className="text-red-400 font-semibold cursor-pointer hover:text-[#5759da] duration-200 ease-in mt-2"
            >
              Forgot Password?
            </Link>

            <div className="w-[90%] sm:w-[70%] h-10 flex items-center gap-[10px] pl-[15px] sm:pl-[0px]">
              <p className="text-sm">Don't have an Account?</p>
              <Link
                to="/register"
                className="text-[#265786] animate-bounce cursor-pointer hover:text-[#5759da] duration-200 ease-in"
              >
                SignUp Here
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
